import Waypoint from 'waypoints';

function toggleMobileNavState() {
  const headerWrapper = document.querySelector('.header__wrapper');
  const hamburgerButton = document.querySelector('.mobile-menu__hamburger-icon');
  const hamburgerIconExpandedState = hamburgerButton.getAttribute('aria-expanded');

  if (hamburgerIconExpandedState === 'true') {
    headerWrapper.classList.remove('mobile--open');
    hamburgerButton.setAttribute('aria-expanded', 'false');
  } else {
    headerWrapper.classList.add('mobile--open');
    hamburgerButton.setAttribute('aria-expanded', 'true');
  }
}

export function initMobileMenu() {
  const hamburgerIcon = document.querySelector('.mobile-menu__hamburger-icon');
  const closeButton = document.querySelector('.mobile-menu__close-icon');
  hamburgerIcon.addEventListener('click', toggleMobileNavState);
  closeButton.addEventListener('click', toggleMobileNavState);
}

export function initCommerceAwardsNav() {
  const nav = document.querySelector('.nav__container');
  const textyHeroContainer = document.querySelector('.texty-hero__container');
  const logo = document.querySelector('.logo-wrapper');

  // eslint-disable-next-line no-new
  new Waypoint({
    element: nav,
    offset: '5%',
    handler() {
      if (textyHeroContainer) {
        textyHeroContainer.classList.add('hidden');
      }
      logo.classList.add('show');
    },
  });
}
